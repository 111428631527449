import React, { useEffect, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, Input, Select, Button, notification, Checkbox } from 'antd';
import { UserContext } from '../../App';
import { SERVER_URL } from '../../config';
import { ReactComponent as TagIcon } from '../../tag-svgrepo-com.svg';
import { ReactComponent as TruckIcon } from '../../truck-svgrepo-com.svg';
import { ReactComponent as ClientIcon } from '../../person-svgrepo-com.svg';
import { ReactComponent as BuidlingIcon } from '../../building-svgrepo-com.svg';
import { ReactComponent as LetterIcon } from '../../letter-svgrepo-com.svg';
import { ReactComponent as OrderIcon } from '../../browser-order-svgrepo-com.svg';
import axios from 'axios';
import { FolderOpenOutlined } from '@ant-design/icons';
import { CheckCircleOutlined } from '@ant-design/icons';

const dateOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
};

const ViewUser = (props, editPath) => {
  const { id } = props.match.params;
  const currentuser = useContext(UserContext);
  const [user, setUser] = useState({});
  const [render, triggerRender] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(user.status);
  const [password, setPassword] = useState(null);

  const [disableFields, setDisableFields] = useState(true);

  const handleChangeStatus = (value) => {
    setSelectedStatus(value);
  };
  const updateUser = async () => {
    try {
      let body = { status: user.status, noBonus: user.noBonus, noAvans: user.noAvans };

      if (password) {
        body.password = password;
      }

      await axios.put(`${SERVER_URL}/users/${id}`, body, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Podaci su izmenjeni.',
        placement: 'bottomRight',
      });
      props.history.push('/admin');
      props.history.push('/admin/users');
    } catch (err) {
      notification.error({
        message: 'Problem sa izmenom. Molim Vas da pokušate kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  const fetchUser = async () => {
    try {
      const res = await axios.get(`${SERVER_URL}/users/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      setUser(res.data);
    } catch (error) {
      console.log('error', error);
    }
  };

  const { Option } = Select;

  useEffect(() => {
    if (id) {
      fetchUser();
    }
    return () => {};
  }, [id]);

  const handleChangeInput = (value, fieldName) => {
    let tempUser = { ...user };
    tempUser[fieldName] = value;
    setUser(tempUser);
    triggerRender((prev) => !prev);
  };

  /*
    branches: [{
      name: { type: String },
      address: { type: String },
      zip: { type: String },
      city: { type: String },
      phone: { type: String },
    }],
  */

  const columns = [
    {
      key: 'orderNumber',
      title: 'Dobavljač',
      render: (text, record, index) => <span>{record?.supplierId?.title?.en}</span>,
    },
    {
      key: 'discount',
      title: 'Rabat',
      render: (text, record, index) => <span>{record.discount}</span>,
    },
    {
      key: 'currency',
      title: 'Valuta',
      render: (text, record, index) => <span>{record.currency}</span>,
    },
  ];

  const address = user?.address
    ? user.address
    : user?.street
    ? `${user?.street.name} ${user?.streetNumber}`
    : user?.street;
  const city = user?.place ? user?.place.name : user?.deliveryCity;

  const mpUser = {
    user: {
      fullName: user?.legalType === 'private' ? `${user?.firstName} ${user?.lastName}` : user?.fullName,
    },
    adresa: {
      adresaIsporuke: address,
      gradIsporuke: city,
    },
    zipDelivery: user?.deliveryAddressSame === true ? user?.zip : user?.zipDelivery,
  };

  return (
    <>
      <div className='edit-panel' style={{ boxShadow: 'unset' }}>
        <div className='content content-full-height'>
          <span className='vp-user-header'>PODACI O KUPCU</span>
          {id && user && Object.keys(user).length > 0 && user.legalType === 'corporate' && (
            <div style={{ display: 'flex', marginTop: '25px' }}>
              <div style={{ width: '35%', minWidth: '350px', padding: '10px' }}>
                <div>
                  <div className='info-panel-head'>
                    <span className='info-panel-head-text'>Podaci o kompaniji</span>
                  </div>
                  <div className='info-panel-content-container'>
                    <div className='row-container'>
                      <span className='label-input'>Kompanija</span>
                      <Input
                        className='text-input'
                        onChange={handleChangeInput}
                        name='companyName'
                        value={user.legalData?.companyName}
                        disabled={disableFields}
                      ></Input>
                    </div>
                    <div className='row-container'>
                      <span className='label-input'>Ulica i broj</span>
                      <Input
                        className='text-input'
                        onChange={handleChangeInput}
                        name='address'
                        value={user.legalData?.address}
                        disabled={disableFields}
                      ></Input>
                    </div>
                    <div className='row-container'>
                      <span className='label-input'>Grad</span>
                      <Input
                        className='text-input'
                        onChange={handleChangeInput}
                        name='city'
                        value={user.legalData?.city}
                        disabled={disableFields}
                      ></Input>
                    </div>
                    <div className='row-container'>
                      <span className='label-input'>PTT</span>
                      <Input
                        className='text-input'
                        onChange={handleChangeInput}
                        name='zip'
                        value={user.legalData?.zip}
                        disabled={disableFields}
                      ></Input>
                    </div>
                    <div className='row-container'>
                      <span className='label-input'>Matični broj</span>
                      <Input
                        className='text-input'
                        onChange={handleChangeInput}
                        name='MB'
                        value={user.legalData?.MB}
                        disabled={disableFields}
                      ></Input>
                    </div>
                    <div className='row-container'>
                      <span className='label-input'>PIB</span>
                      <Input
                        className='text-input'
                        onChange={handleChangeInput}
                        name='PIB'
                        value={user.legalData?.PIB}
                        disabled={disableFields}
                      ></Input>
                    </div>
                    <div className='row-container'>
                      <span className='label-input'>Rola</span>
                      <Input
                        className='text-input'
                        onChange={handleChangeInput}
                        name='role'
                        value={user.role}
                        disabled={disableFields}
                      ></Input>
                    </div>
                    <div className='row-container'>
                      <span className='label-input'>Status</span>
                      <Select
                        disabled={currentuser.data.role !== 'admin'}
                        className='dropdown-input'
                        onChange={(value) => handleChangeInput(value, 'status')}
                        value={user.status}
                        style={{ width: '105%', height: '35px', borderRadius: '5px' }}
                      >
                        <Option value='WAITING_FOR_ACTIVATION'>
                          {user.status === 'WAITING_FOR_ACTIVATION' ? (
                            <CheckCircleOutlined style={{ marginRight: '5px', color: '#fe602c' }} />
                          ) : (
                            <span style={{ marginRight: '5px', color: 'gray' }}>&#9898;</span>
                          )}
                          Cekanje na aktivaciju
                        </Option>
                        <Option value='ACTIVE'>
                          {user.status === 'ACTIVE' ? (
                            <CheckCircleOutlined style={{ marginRight: '5px', color: '#fe602c' }} />
                          ) : (
                            <span style={{ marginRight: '5px', color: 'gray' }}>&#9898;</span>
                          )}
                          Aktivan
                        </Option>
                        <Option value='SUSPENDED'>
                          {user.status === 'SUSPENDED' ? (
                            <CheckCircleOutlined style={{ marginRight: '5px', color: '#fe602c' }} />
                          ) : (
                            <span style={{ marginRight: '5px', color: 'gray' }}>&#9898;</span>
                          )}
                          Suspendovan
                        </Option>
                        <Option value='DELETED'>
                          {user.status === 'DELETED' ? (
                            <CheckCircleOutlined style={{ marginRight: '5px', color: '#fe602c' }} />
                          ) : (
                            <span style={{ marginRight: '5px', color: 'gray' }}>&#9898;</span>
                          )}
                          Obrisan
                        </Option>
                      </Select>
                    </div>
                    <div className='row-container'>
                      <span className='label-input'>Komercijalista</span>
                      <Input
                        className='text-input'
                        onChange={handleChangeInput}
                        name='commercialist'
                        value={user.commercialist}
                        disabled={disableFields}
                      ></Input>
                    </div>

                    {currentuser.data.role === 'admin' && (
                      <>
                        <div className='row-container'>
                          <span className='label-input'>Nova šifra</span>
                          <Input
                            className='text-input'
                            onChange={(e) => setPassword(e.target.value)}
                            name='password'
                            value={password}
                          ></Input>
                        </div>
                        <div className='row-container' style={{ alignItems: 'center' }}>
                          <span className='label-input'>Ukloni nagradu</span>
                          <div style={{ width: '100%', display: 'flex', alignItems:'center', gap: '25px' }}>
                            <Checkbox
                              checked={user.noBonus}
                              onChange={(e) => handleChangeInput(e.target.checked, 'noBonus')}
                            />
                            <div className='noAvansDiv'>
                              <span>Ukloni Avans</span>
                              <Checkbox checked={user.noAvans} onChange={(e) => handleChangeInput(e.target.checked, 'noAvans')} />
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div>
                  <div className='info-panel-head'>
                    <span className='info-panel-head-text'>Kontakt podaci</span>
                  </div>
                  <div className='info-panel-content-container'>
                    <div className='row-container'>
                      <span className='label-input'>Ime</span>
                      <Input
                        className='text-input'
                        onChange={handleChangeInput}
                        name='firstName'
                        value={user.firstName}
                        disabled={disableFields}
                      ></Input>
                    </div>
                    <div className='row-container'>
                      <span className='label-input'>Prezime</span>
                      <Input
                        className='text-input'
                        onChange={handleChangeInput}
                        name='lastName'
                        value={user.lastName}
                        disabled={disableFields}
                      ></Input>
                    </div>
                    <div className='row-container'>
                      <span className='label-input'>Email</span>
                      <Input
                        className='text-input'
                        onChange={handleChangeInput}
                        name='email'
                        value={user.email}
                        disabled={disableFields}
                      ></Input>
                    </div>
                    <div className='row-container'>
                      <span className='label-input'>Telefon</span>
                      <Input
                        className='text-input'
                        onChange={handleChangeInput}
                        name='phone1'
                        value={user.phone1}
                        disabled={disableFields}
                      ></Input>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ width: '35%', minWidth: '350px', padding: '10px' }}>
                <div>
                  <div className='info-panel-head'>
                    <span className='info-panel-head-text'>Adresa za slanje 1</span>
                  </div>
                  <div
                    style={{
                      marginBottom: '25px',
                      paddingTop: '10px',
                      display: 'flex',
                      flexDirection: 'column',
                      minWidth: '250px',
                    }}
                  >
                    <div className='row-container'>
                      <span className='label-input'>Naziv</span>
                      <Input
                        className='text-input'
                        onChange={handleChangeInput}
                        name='name'
                        value={user.branches[0]?.name}
                        disabled={disableFields}
                      ></Input>
                    </div>
                    <div className='row-container'>
                      <span className='label-input'>Ulica i broj</span>
                      <Input
                        className='text-input'
                        onChange={handleChangeInput}
                        name='address'
                        value={user.branches[0]?.address}
                        disabled={disableFields}
                      ></Input>
                    </div>
                    <div className='row-container'>
                      <span className='label-input'>Grad</span>
                      <Input
                        className='text-input'
                        onChange={handleChangeInput}
                        name='city'
                        value={user.branches[0]?.city}
                        disabled={disableFields}
                      ></Input>
                    </div>
                    <div className='row-container'>
                      <span className='label-input'>PTT</span>
                      <Input
                        className='text-input'
                        onChange={handleChangeInput}
                        name='zip'
                        value={user.branches[0]?.zip}
                        disabled={disableFields}
                      ></Input>
                    </div>
                    <div className='row-container'>
                      <span className='label-input'>Telefon</span>
                      <Input
                        className='text-input'
                        onChange={handleChangeInput}
                        name='phone'
                        value={user.branches[0]?.phone}
                        disabled={disableFields}
                      ></Input>
                    </div>
                  </div>
                </div>
                <div>
                  <div className='info-panel-head'>
                    <span className='info-panel-head-text'>Adresa za slanje 2</span>
                  </div>
                  <div
                    style={{
                      marginBottom: '25px',
                      paddingTop: '10px',
                      display: 'flex',
                      flexDirection: 'column',
                      minWidth: '250px',
                    }}
                  >
                    <div className='row-container'>
                      <span className='label-input'>Naziv</span>
                      <Input
                        className='text-input'
                        onChange={handleChangeInput}
                        name='name'
                        value={user.branches[1]?.name}
                        disabled={disableFields}
                      ></Input>
                    </div>
                    <div className='row-container'>
                      <span className='label-input'>Ulica i broj</span>
                      <Input
                        className='text-input'
                        onChange={handleChangeInput}
                        name='address'
                        value={user.branches[1]?.address}
                        disabled={disableFields}
                      ></Input>
                    </div>
                    <div className='row-container'>
                      <span className='label-input'>Grad</span>
                      <Input
                        className='text-input'
                        onChange={handleChangeInput}
                        name='city'
                        value={user.branches[1]?.city}
                        disabled={disableFields}
                      ></Input>
                    </div>
                    <div className='row-container'>
                      <span className='label-input'>PTT</span>
                      <Input
                        className='text-input'
                        onChange={handleChangeInput}
                        name='zip'
                        value={user.branches[1]?.zip}
                        disabled={disableFields}
                      ></Input>
                    </div>
                    <div className='row-container'>
                      <span className='label-input'>Telefon</span>
                      <Input
                        className='text-input'
                        onChange={handleChangeInput}
                        name='phone'
                        value={user.branches[1]?.phone}
                        disabled={disableFields}
                      ></Input>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div style={{ width: '25%', minWidth: '290px', padding: '10px' }}>
                <span style={{ color: '#FE602C', fontSize: '0.7rem', fontWeight: 600 }}>
                  <TruckIcon style={{ fill: '#555', height: '25px', width: '25px', marginRight: '5px' }} />
                  <>
                    PODACI ZA DOSTAVU
                  </>
                </span>
                <div style={{ marginBottom: '25px', paddingTop: '10px', display: 'flex', flexDirection: 'column', maxWidth: '90%', minWidth: '250px' }}>
                  <Input style={{ margin: '4px 0', border: '1px solid #d4d4d4', padding: '1px 5px', height: '35px', borderRadius: '2px' }} value={user.branches[0]?.name}></Input>
                  <Input style={{ margin: '4px 0', border: '1px solid #d4d4d4', padding: '1px 5px', height: '35px', borderRadius: '2px' }} value={user.branches[0]?.address}></Input>
                  <div style={{ display: 'flex', width: '100%' }}>
                    <Input style={{ margin: '4px 0', border: '1px solid #d4d4d4', padding: '1px 5px', height: '35px', width: '30%', marginRight: '10px', borderRadius: '2px' }} value={user.branches[0]?.zip}></Input>
                    <Input style={{ margin: '4px 0', border: '1px solid #d4d4d4', padding: '1px 5px', height: '35px', width: '70%', borderRadius: '2px' }} value={user.branches[0]?.city}></Input>
                  </div>
                  <Input style={{ margin: '4px 0', border: '1px solid #d4d4d4', padding: '1px 5px', height: '35px', borderRadius: '2px' }} value={user.branches[0]?.phone}></Input>
                </div>
                <div style={{ marginBottom: '25px', paddingTop: '61px', display: 'flex', flexDirection: 'column', maxWidth: '90%', minWidth: '250px' }}>
                  <Input style={{ margin: '4px 0', border: '1px solid #d4d4d4', padding: '1px 5px', height: '35px', borderRadius: '2px' }} value={user.branches[1]?.name}></Input>
                  <Input style={{ margin: '4px 0', border: '1px solid #d4d4d4', padding: '1px 5px', height: '35px', borderRadius: '2px' }} value={user.branches[1]?.address}></Input>
                  <div style={{ display: 'flex', width: '100%' }}>
                    <Input style={{ margin: '4px 0', border: '1px solid #d4d4d4', padding: '1px 5px', height: '35px', width: '30%', marginRight: '10px', borderRadius: '2px' }} value={user.branches[1]?.zip}></Input>
                    <Input style={{ margin: '4px 0', border: '1px solid #d4d4d4', padding: '1px 5px', height: '35px', width: '70%', borderRadius: '2px' }} value={user.branches[1]?.city}></Input>
                  </div>
                  <Input style={{ margin: '4px 0', border: '1px solid #d4d4d4', padding: '1px 5px', height: '35px', borderRadius: '2px' }} value={user.branches[1]?.phone}></Input>
                </div>
              </div> */}
              {user.supplierDiscount && user.supplierDiscount.length > 0 && (
                <div style={{ width: '30%', minWidth: '350px', padding: '10px' }}>
                  <div>
                    <Table columns={columns} dataSource={user.supplierDiscount} pagination={false} rowKey='_id' />
                    {/* <span style={{ color: '#FE602C', fontSize: '0.7rem', fontWeight: 600 }}>
                      <TagIcon style={{ fill: '#555', height: '25px', width: '25px', marginRight: '5px' }} />
                      <>
                        RABATI I VALUTE PLACANJA
                      </>
                    </span>
                    <div style={{ marginBottom: '25px', paddingTop: '10px', display: 'flex', flexDirection: 'column', maxWidth: '90%', minWidth: '450px' }}>
                      {user.supplierDiscount && user.supplierDiscount.length > 0 && user.supplierDiscount.map((item) => (
                        <div className='row-container'>
                          <Input style={{ margin: '4px 0', border: '1px solid #d4d4d4', borderRight: 'unset', padding: '1px 5px', height: '35px', borderRadius: '2px', width: '35%' }} value={item.supplierId.title.en}></Input>
                          <Input style={{ backgroundColor: 'rgb(254, 96, 44)', color: '#fff', textAlign: 'center', margin: '4px 0', border: '1px solid #d4d4d4', borderRight: 'unset', padding: '1px 5px', height: '35px', borderRadius: '2px', width: '20%' }} value={`RABAT ${item.discount}%`}></Input>
                          <Input style={{ margin: '4px 0', border: '1px solid #d4d4d4', padding: '1px 5px', textAlign: 'center', height: '35px', borderRadius: '2px', width: '35%', color: 'rgb(254, 96, 44)' }} value={`VALUTA ${item.currency} DANA`}></Input>
                        </div>
                      ))
                      }
                    </div> */}
                  </div>
                </div>
              )}
            </div>
          )}

          {id && user && Object.keys(user).length > 0 && user.legalType === 'private' ? (
            <div style={{ display: 'flex' }}>
              <div style={{ width: '25%', minWidth: '290px', padding: '10px' }}>
                <div>
                  <span style={{ color: '#FE602C', fontSize: '0.7rem', fontWeight: 600 }}>
                    <ClientIcon style={{ height: '25px', width: '25px', marginRight: '5px', color: '#555' }} />
                    <>KONTAKT INFORMACIJE</>
                  </span>
                  <div
                    style={{
                      marginBottom: '25px',
                      paddingTop: '10px',
                      display: 'flex',
                      flexDirection: 'column',
                      maxWidth: '90%',
                      minWidth: '250px',
                    }}
                  >
                    <input
                      style={{ margin: '4px 0', border: '1px solid #d4d4d4', padding: '1px 5px', height: '35px' }}
                      readOnly
                      value={mpUser.user.fullName}
                      disabled={disableFields}
                    ></input>
                    <input
                      style={{ margin: '4px 0', border: '1px solid #d4d4d4', padding: '1px 5px', height: '35px' }}
                      readOnly
                      value={user?.phone[0]}
                      disabled={disableFields}
                    ></input>
                    <input
                      style={{ margin: '4px 0', border: '1px solid #d4d4d4', padding: '1px 5px', height: '35px' }}
                      readOnly
                      value={user?.phone2}
                      disabled={disableFields}
                    ></input>
                    <input
                      style={{ margin: '4px 0', border: '1px solid #d4d4d4', padding: '1px 5px', height: '35px' }}
                      readOnly
                      value={user?.email}
                      disabled={disableFields}
                    ></input>
                  </div>
                </div>
                <div style={{ marginTop: '51px' }}>
                  <span style={{ color: '#FE602C', fontSize: '0.7rem', fontWeight: 600 }}>
                    <BuidlingIcon style={{ fill: '#555', height: '25px', width: '25px', marginRight: '5px' }} />
                    <>PODACI O PRAVNOM LICU</>
                  </span>
                  <div
                    style={{
                      marginBottom: '25px',
                      paddingTop: '10px',
                      display: 'flex',
                      flexDirection: 'column',
                      maxWidth: '90%',
                      minWidth: '250px',
                    }}
                  >
                    <input
                      style={{
                        margin: '4px 0',
                        border: '1px solid #d4d4d4',
                        padding: '1px 5px',
                        height: '35px',
                        borderRadius: '2px',
                      }}
                      readOnly
                      value={user?.companyName === 'null' ? null : user?.companyName}
                      disabled={disableFields}
                    ></input>
                    <input
                      style={{
                        margin: '4px 0',
                        border: '1px solid #d4d4d4',
                        padding: '1px 5px',
                        height: '35px',
                        borderRadius: '2px',
                      }}
                      readOnly
                      value={user?.legalData?.address}
                      disabled={disableFields}
                    ></input>
                    <div style={{ display: 'flex', width: '100%' }}>
                      <input
                        style={{
                          margin: '4px 0',
                          border: '1px solid #d4d4d4',
                          padding: '1px 5px',
                          height: '35px',
                          width: '30%',
                          marginRight: '10px',
                          borderRadius: '2px',
                        }}
                        readOnly
                        value={user?.legalData?.zip}
                        disabled={disableFields}
                      ></input>
                      <input
                        style={{
                          margin: '4px 0',
                          border: '1px solid #d4d4d4',
                          padding: '1px 5px',
                          height: '35px',
                          width: '70%',
                          borderRadius: '2px',
                        }}
                        readOnly
                        value={user?.legalData?.city}
                        disabled={disableFields}
                      ></input>
                    </div>
                    <input
                      style={{
                        margin: '4px 0',
                        border: '1px solid #d4d4d4',
                        padding: '1px 5px',
                        height: '35px',
                        borderRadius: '2px',
                      }}
                      readOnly
                      value={`PIB: ${user?.legalData?.PIB}`}
                      disabled={disableFields}
                    ></input>
                    <input
                      style={{
                        margin: '4px 0',
                        border: '1px solid #d4d4d4',
                        padding: '1px 5px',
                        height: '35px',
                        borderRadius: '2px',
                      }}
                      readOnly
                      value={`MB: ${user?.legalData?.MB}`}
                      disabled={disableFields}
                    ></input>
                    <input
                      style={{
                        margin: '4px 0',
                        border: '1px solid #d4d4d4',
                        padding: '1px 5px',
                        height: '35px',
                        borderRadius: '2px',
                      }}
                      readOnly
                      value={user?.legalData?.phone}
                      disabled={disableFields}
                    ></input>
                  </div>
                </div>
              </div>
              <div style={{ width: '25%', minWidth: '290px', padding: '10px' }}>
                <span style={{ color: '#FE602C', fontSize: '0.7rem', fontWeight: 600 }}>
                  <TruckIcon style={{ fill: '#555', height: '25px', width: '25px', marginRight: '5px' }} />
                  <>PODACI ZA DOSTAVU</>
                </span>
                <div
                  style={{
                    marginBottom: '25px',
                    paddingTop: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: '90%',
                    minWidth: '250px',
                  }}
                >
                  <input
                    style={{
                      margin: '4px 0',
                      border: '1px solid #d4d4d4',
                      padding: '1px 5px',
                      height: '35px',
                      borderRadius: '2px',
                    }}
                    readOnly
                    value={user?.branches[0]?.name ? user?.branches[0]?.name : `${user?.firstName} ${user?.lastName}`}
                    disabled={disableFields}
                  ></input>
                  <input
                    style={{
                      margin: '4px 0',
                      border: '1px solid #d4d4d4',
                      padding: '1px 5px',
                      height: '35px',
                      borderRadius: '2px',
                    }}
                    readOnly
                    value={mpUser.adresa.adresaIsporuke}
                    //
                    disabled={disableFields}
                  ></input>
                  <div style={{ display: 'flex', width: '100%' }}>
                    <input
                      style={{
                        margin: '4px 0',
                        border: '1px solid #d4d4d4',
                        padding: '1px 5px',
                        height: '35px',
                        width: '30%',
                        marginRight: '10px',
                        borderRadius: '2px',
                      }}
                      readOnly
                      value={user?.branches[0]?.zip ? user?.branches[0]?.zip : mpUser.zipDelivery}
                      disabled={disableFields}
                    ></input>
                    <input
                      style={{
                        margin: '4px 0',
                        border: '1px solid #d4d4d4',
                        padding: '1px 5px',
                        height: '35px',
                        width: '70%',
                        borderRadius: '2px',
                      }}
                      readOnly
                      value={mpUser.adresa.gradIsporuke}
                      disabled={disableFields}
                    ></input>
                  </div>
                  <input
                    style={{
                      margin: '4px 0',
                      border: '1px solid #d4d4d4',
                      padding: '1px 5px',
                      height: '35px',
                      borderRadius: '2px',
                    }}
                    readOnly
                    value={user?.branches[0]?.phone ? user?.branches[0]?.phone : user?.phone[0]}
                    disabled={disableFields}
                  ></input>
                </div>
                <div
                  style={{
                    marginBottom: '25px',
                    paddingTop: '61px',
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: '90%',
                    minWidth: '250px',
                  }}
                >
                  <input
                    style={{
                      margin: '4px 0',
                      border: '1px solid #d4d4d4',
                      padding: '1px 5px',
                      height: '35px',
                      borderRadius: '2px',
                    }}
                    readOnly
                    value={user?.branches[1]?.name}
                    disabled={disableFields}
                  ></input>
                  <input
                    style={{
                      margin: '4px 0',
                      border: '1px solid #d4d4d4',
                      padding: '1px 5px',
                      height: '35px',
                      borderRadius: '2px',
                    }}
                    readOnly
                    value={user?.branches[1]?.address}
                    disabled={disableFields}
                  ></input>
                  <div style={{ display: 'flex', width: '100%' }}>
                    <input
                      style={{
                        margin: '4px 0',
                        border: '1px solid #d4d4d4',
                        padding: '1px 5px',
                        height: '35px',
                        width: '30%',
                        marginRight: '10px',
                        borderRadius: '2px',
                      }}
                      readOnly
                      value={user?.branches[1]?.zip}
                      disabled={disableFields}
                    ></input>
                    <input
                      style={{
                        margin: '4px 0',
                        border: '1px solid #d4d4d4',
                        padding: '1px 5px',
                        height: '35px',
                        width: '70%',
                        borderRadius: '2px',
                      }}
                      readOnly
                      value={user?.branches[1]?.city}
                      disabled={disableFields}
                    ></input>
                  </div>
                  <input
                    style={{
                      margin: '4px 0',
                      border: '1px solid #d4d4d4',
                      padding: '1px 5px',
                      height: '35px',
                      borderRadius: '2px',
                    }}
                    readOnly
                    value={user?.branches[1]?.phone}
                    disabled={disableFields}
                  ></input>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '12px',
                      margin: '4px 0',
                      height: '35px',
                    }}
                  >
                    <span>Ukloni nagradu</span>
                    <Checkbox
                      checked={user.noBonus}
                      onChange={(e) => handleChangeInput(e.target.checked, 'noBonus')}
                      id='noBonus'
                    />
                  </div>
                  <div style={{display:"flex", alignItems:'center',gap:'12px',margin:'4px 0', height:'35px'}}>
                    <span>Ukloni avans</span>
                    <Checkbox checked={user.noAvans} id='noAvans' onChange={(e) => handleChangeInput(e.target.checked, 'noAvans')} />
                  </div>
                </div>
              </div>
              <div style={{ width: '50%', minWidth: '350px', padding: '10px' }}>
                {/* <button onClick={() => updateUser()}>xd</button> */}
                {/* <div>
                  <span style={{ color: '#FE602C', fontSize: '0.7rem', fontWeight: 600 }}>
                    <LetterIcon style={{ fill: '#555', height: '25px', width: '25px', marginRight: '5px' }} />
                    <>
                      NEWSLETTER
                    </>
                  </span>
                  <div style={{ marginBottom: '25px', paddingTop: '10px', display: 'flex', flexDirection: 'column', maxWidth: '90%', minWidth: '450px' }}>
                    <div>
                      <p style={{ marginBottom: '10px' }}>Prijavljeni ste na naš newsletter.</p>
                      <button style={{ textDecoration: 'underline' }}>Odjavi se</button>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {/* <div style={{ height: '250px', marginTop: '25px' }}>
        <div style={{ display: 'flex', padding: '10px', marginBottom: '25px' }}>
          <OrderIcon style={{ fill: '#555', height: '20px', width: '20px', marginRight: '5px' }} />
          <span style={{ color: 'rgb(254, 96, 44)' }}>NEDAVNE PORUDŽBINE</span>
        </div>
        {orders && orders.data && orders.data.items && orders.data.items.length > 0 &&
          <div className='user-table'>
            <Table
              dataSource={orders.data.items}
              columns={columns}
              pagination={false}
              bordered={false}
            />
          </div>
        }
      </div> */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          marginBottom: '40px',
        }}
      >
        <Button
          style={{
            backgroundColor: '#fe602c',
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            marginRight: '10px',
            marginLeft: '10px',
            borderRadius: 0,
          }}
          onClick={updateUser}
        >
          <FolderOpenOutlined style={{ marginRight: '5px' }} />
          SAČUVAJ
        </Button>
      </div>
    </>
  );
};

export default ViewUser;
