import React, { useContext, useState } from 'react';
import { Table, Input, Button } from 'antd';
import Icon, { SearchOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import { useHistory } from 'react-router-dom';

const RefreshSvg = () => (
  <svg fill='#4d4d4d' id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 118.04 122.88'>
    <path d='M16.08,59.26A8,8,0,0,1,0,59.26a59,59,0,0,1,97.13-45V8a8,8,0,1,1,16.08,0V33.35a8,8,0,0,1-8,8L80.82,43.62a8,8,0,1,1-1.44-15.95l8-.73A43,43,0,0,0,16.08,59.26Zm22.77,19.6a8,8,0,0,1,1.44,16l-10.08.91A42.95,42.95,0,0,0,102,63.86a8,8,0,0,1,16.08,0A59,59,0,0,1,22.3,110v4.18a8,8,0,0,1-16.08,0V89.14h0a8,8,0,0,1,7.29-8l25.31-2.3Z' />
  </svg>
);
const RefreshIcon = (props) => <Icon component={RefreshSvg} {...props} />;

const DataTable = ({
  data,
  deleteHandler,
  columnKeys,
  title,
  editPath,
  viewPath,
  increment,
  decrement,
  discount,
  removeProduct,
  changeOrderItemStatus,
}) => {
  const history = useHistory();
  const user = useContext(UserContext);
  const [reload, triggerReload] = useState(false);
  let searchInput;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Pretraga za${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 220, marginBottom: 8, display: 'block' }}
          />
          <button
            className='b2b-primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            // size='small'
            style={{ width: 90, marginRight: 8 }}
          >
            Traži
          </button>
          <button
            className='b2b-secondary'
            onClick={() => handleReset(clearFilters)}
            // size='small'
            style={{ width: 90 }}
          >
            Reset
          </button>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? 'red' : '#fff', fontSize: '1rem' }} />,
    onFilter: (value, record) => {
      const filterRecord = Array.isArray(dataIndex)
        ? !dataIndex.includes('category')
          ? record[dataIndex[0]][dataIndex[1]]
          : record[dataIndex[0]][dataIndex[1]][dataIndex[2]]
        : record[dataIndex];
      if (!filterRecord || !value) return false;
      return filterRecord.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          searchInput.select();
        });
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };
  const getRowClassName = (record) => {
    if (record.status === 1) {
      return 'greenBackgroundColor';
    } else if (record.status === 2) {
      return 'blueBackgroundColor';
    } else if (record.status === 3) {
      return 'yellowBackgroundColor';
    } else if (record.status === 4) {
      return 'redBackgroundColor';
    } else {
      return 'noBackgroundColor';
    }
  };

  const columns = columnKeys.map((item) => ({
    key: item,
    title: item,
    dataIndex:
      item === 'createdBy'
        ? 'user'
        : ['title', 'category'].includes(item)
        ? item === 'title'
          ? [item, user.language ? user.language.selected.code : '']
          : [item, 'name', user.language ? user.language.selected.code : '']
        : item,
    ...getColumnSearchProps(
      item === 'createdBy'
        ? 'user'
        : ['title', 'category'].includes(item)
        ? item === 'title'
          ? [item, user.language ? user.language.selected.code : '']
          : [item, 'name', user.language ? user.language.selected.code : '']
        : item,
    ),
  }));

  if (history.location.pathname.includes('/admin/view-order/')) {
    columns.splice(3, 0, {
      title: 'JM',
      render: (record) => <p>KOM</p>,
    });
    columns.splice(4, 0, {
      title: 'Pakovanje',
      render: (record) => <p>{record.packageQuantity}</p>,
    });
    columns.splice(6, 0, {
      title: 'Rabat',
      render: (record) => {
        return (
          <p>{record.discount}% {record.additionalDiscount ? (
            <span style={{color: '#f84804'}}>+{record.additionalDiscount}%</span>
          ) : null}</p>
        )
      },
    });
    columns.splice(7, 0, {
      title: 'Vrednost',
      render: (record) => {
        return (
          <p>{(record.price * record.quantity * ((100 - record.discount) / 100) * ((100 - (record.additionalDiscount ? record.additionalDiscount : 0)) / 100)).toFixed(2)}</p>
        )
      },
    });

    // columns.splice(2, 0, {
    //   title: 'discount',
    //   render: (record) => (
    //     <div style={{ margin: '0 auto', width: '60px', outline: '0', borderBottom: '1px solid black' }}>
    //       <Input
    //         bordered={false}
    //         placeholder='%'
    //         onChange={(e) => discount(e.currentTarget.value, record)}
    //         defaultValue={record.discountPercentage && record.discountPercentage}
    //       />
    //     </div>
    //   ),
    // });

    columns.push({
      title: 'status',
      render: (record) => (
        <div
          className='refresh-icon'
          style={{ display: 'inline-flex', justifyContent: 'left', alignItems: 'center', fontSize: '15px' }}
        >
          <Button
            disabled={user.data.role !== 'admin'}
            onClick={() => {
              changeOrderItemStatus(record);
              getRowClassName(record);
            }}
            style={{ marginRight: '10px' }}
          >
            <RefreshIcon />
          </Button>
        </div>
      ),
    });
    // columns.push({
    //   title: 'Subtotal',
    //   render: (record) => (
    //     <span>
    //       {record.discountPercentage
    //         ? (record.quantity * (record.price - (record.price * +record.discountPercentage) / 100)).toFixed(2)
    //         : (record.quantity * record.price).toFixed(2)}
    //     </span>
    //   ),
    // });
    // columns.push({
    //   title: 'action',
    //   render: (record) => (
    //     <div style={{ margin: '2px', padding: '4px' }} className='lock'>
    //       <Popconfirm
    //         placement='left'
    //         title={`Da li želite da uklonite proizvod?`}
    //         onConfirm={() => removeProduct(record._id)}
    //         okText='U redu'
    //         cancelText='Poništi'
    //         cancelButtonProps={{ className: 'b2b-secondary' }}
    //         okButtonProps={{ className: 'b2b-primary' }}
    //       >
    //         <DeleteOutlined title={`Obriši proizvod`} />
    //       </Popconfirm>
    //     </div>
    //   ),
    // });
  } else {
    // columns.push({
    //   title: 'action',
    //   render: (text, record) => (
    //     <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', fontSize: '15px' }}>
    //       <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
    //         <Link to={editPath + record._id}>
    //           <EditOutlined
    //             className='icon-unlock'
    //             // title={`Edit ${title.toLowerCase()}`}
    //             title={`Izmena proizvoda`}
    //             style={{ textDecoration: 'none', color: 'black' }}
    //           />
    //           <EditFilled
    //             className='icon-lock'
    //             // title={`Edit ${title.toLowerCase()}`}
    //             title={`Izmena proizvoda`}
    //             style={{ textDecoration: 'none', color: 'black' }}
    //           />
    //         </Link>
    //       </div>
    //       {viewPath && (
    //         <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='lock'>
    //           <Link to={viewPath + record._id}>
    //             <EyeOutlined
    //               className='icon-unlock'
    //               title={`View ${title.toLowerCase()}`}
    //               style={{ textDecoration: 'none', color: 'black' }}
    //             />
    //             <EyeOutlined
    //               className='icon-lock'
    //               title={`View ${title.toLowerCase()}`}
    //               style={{ textDecoration: 'none', color: 'black' }}
    //             />
    //           </Link>
    //         </div>
    //       )}
    //       <div style={{ margin: '2px', padding: '4px' }} className='lock'>
    //         <Popconfirm
    //           disabled={record._id === user.data.id}
    //           placement='left'
    //           // title={`This will delete ${title.toLowerCase()}`}
    //           title={`Ovo će izbrisati proizvod`}
    //           onConfirm={() => {
    //             deleteHandler(record._id);
    //           }}
    //           okText='U redu'
    //           cancelText='Poništi'
    //           cancelButtonProps={{ className: 'b2b-secondary' }}
    //           okButtonProps={{ className: 'b2b-primary' }}
    //         >
    //           <DeleteOutlined
    //             className='icon-unlock'
    //             // title={`Delete ${title.toLowerCase()}`}
    //             title={`Brisanje proizvoda`}
    //           />
    //           <DeleteFilled
    //             className='icon-lock'
    //             // title={`Delete ${title.toLowerCase()}`}
    //             title={`Brisanje proizvoda`}
    //           />
    //         </Popconfirm>
    //       </div>
    //     </div>
    //   ),
    // });
  }

  return (
    <div>
      <Table
        size='middle'
        bordered
        dataSource={data}
        rowClassName={getRowClassName}
        columns={columns}
        rowKey='_id'
        pagination={{
          defaultPageSize: 20,
          position: 'bottom',
          showSizeChanger: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          hideOnSinglePage: true,
        }}
      />
    </div>
  );
};

export default DataTable;
